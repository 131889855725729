import {ContentSection} from '../types/content-section.types'
import {parseRichTextContent} from './rich-text.serde'
import {validateDatoCmsTypes} from './common.serde'
import {ContentSectionFieldsFragment} from '../../../../__generated__/datocms.types'

export function parseContentSection(k: ContentSectionFieldsFragment): ContentSection {
  const entry = validateDatoCmsTypes(k)

  return {
    __type: 'ContentSection',
    content: parseRichTextContent(entry),
    title: entry.title,
    plot: entry.plot,
  }
}
